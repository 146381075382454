import React from 'react';
import PressCard from './PressCard';
import SVICPhoto from '../../assets/images/SVIC_press.png';
import BostInno from '../../assets/images/BostInno_press.png';
import UNHToday from '../../assets/images/UNHToday_press.png';
import MauricePrize from '../../assets/images/Maurice_Prize_Photo.jpg';

const pressArticles = [
  {
    title: "DormDrop featured in UNH Today",
    description: "CEO Brett Schultz discusses the future of DormDrop as the team begins to deploy their platform.",
    webLink: "https://www.unh.edu/unhtoday/2024/03/student-entrepreneur-tackles-social-isolation-new-platform",
    image: UNHToday,
    reverse: false,
  },
  {
    title: "DormDrop wins \"Most Financially Sustainable Solution\"",
    description: "DormDrop takes part in the Social Venture Innovation Challenge at the University of New Hampshire.", 
    webLink: "https://www.unh.edu/sustainability/blog/2023/12/winners-announced-2023-social-venture-innovation-challenge",
    image: SVICPhoto,
    reverse: false,
  },
];

const PressSection = () => {
  return (
    <div className="press-section container mx-auto py-12">
      <h2 className="text-4xl font-bold text-center mb-12">Press</h2>
      <div className="flex flex-wrap justify-center">
        {pressArticles.map((article, index) => (
          <PressCard 
            key={index}
            title={article.title}
            description={article.description}
            webLink={article.webLink}
            image={article.image}
            reverse={article.reverse}
          />
        ))}
      </div>
    </div>
  );
};

export default PressSection;
