import React, { useState, useEffect } from 'react';
import transition1 from '../../../assets/images/transaction_photo_one.png';
import transition2 from '../../../assets/images/transaction_photo_two.png';
import transition3 from '../../../assets/images/transition4.png';
import { Link } from 'react-router-dom';

const heroImages = [
  { url: transition1, alt: 'Description of first image' },
  { url: transition2, alt: 'Description of second image' },
  { url: transition3, alt: 'Description of third image' },
];

function HeroSection() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % heroImages.length);
    }, 6000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <div className="relative w-full overflow-hidden h-screen">
      {heroImages.map((image, index) => (
        <img 
          key={index} 
          src={image.url} 
          alt={image.alt} 
          className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-1000 ${index === currentSlide ? 'opacity-100' : 'opacity-0'}`}
        />
      ))}
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className={`absolute w-full h-full flex items-center justify-center text-center text-white p-4 ${loaded ? 'fadeInUp' : ''}`}>
        <div>
          <h1 className={`text-5xl font-bold mb-4 ${loaded ? 'animate-fadeInUp' : ''}`}>Welcome to DormDrop</h1>
          <p className={`text-xl mb-8 ${loaded ? 'animate-fadeInUp' : ''}`}>Everything you need, on your college campus.</p>
          <Link to="/DownloadNow">
            <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Download Now
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
