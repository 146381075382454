import React from 'react';
import InfoCard from './InfoCard';
import PhotoOne from '../../../assets/images/transaction_photo_one.png';
import PhotoTwo from '../../../assets/images/transaction_photo_two.png';

const infoFeatures = [
  {
    title: "Marketplace Feature",
    description: "Have meals from local restaurants delivered right to your dorm at a rate significantly lower than traditional food delivery apps. We also have functionality so students can sell textbooks and other items with their peers.",
    image: PhotoTwo,
    reverse: true,
  },
  {
    title: "Discovery Feature",
    description: "Find out about clubs, events, and other opportunities that match your interests and meet like-minded students. While we're an ecommerce platform at our core, we see this feature as a way to build close-knit communities on college campuses",
    image: PhotoOne,
    reverse: false,
  },
 
];

const InfoSection = () => {
  return (
    <div className="info-section container mx-auto py-12">
      <h2 className="text-4xl font-bold text-center mb-12">Features</h2>
      <div className="flex flex-wrap justify-center">
        {infoFeatures.map((feature, index) => (
          <InfoCard 
            key={index}
            title={feature.title}
            description={feature.description}
            image={feature.image}
            reverse={feature.reverse}
          />
        ))}
      </div>
    </div>
  );
};

export default InfoSection;
